import React from "react";

import Layout from "../layouts";
import SEO from "../components/seo";

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="flex flex-col justify-center w-full items-center">
        <h2 className="text-4xl font-bold inline-block my-8 p-3">
          Looks Like you are lost. Try navigating to{" "}
          <a className="text-mph-orange" href="https://myproducthub.com">
            Home
          </a>
        </h2>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
